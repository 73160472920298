import React from 'react'

export function AddItem({
  onPressAdd,
  name,
  onNameChange,
  onPressCancel,
  onUrlChange,
  url,
}: {
  onPressAdd: () => void
  onPressCancel: () => void
  name?: string
  url?: string
  onNameChange: React.ChangeEventHandler<HTMLInputElement>
  onUrlChange: React.ChangeEventHandler<HTMLInputElement>
}) {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        opacity: 0.98,
        flexDirection: 'column',
      }}
    >
      <div style={{ width: '80%' }}>
        <div>
          <div>
            <label htmlFor="name" style={{ fontWeight: 'bold' }}>
              Name
            </label>
          </div>
          <div>
            <input
              onChange={onNameChange}
              value={name || ''}
              type="text"
              id="name"
              name="name"
              placeholder=""
              style={{
                padding: '12px 12px',
                margin: '8px 0',
                display: 'inline-block',
                border: '1px solid #ccc',
                borderRadius: '4px',
                boxSizing: 'border-box',
                width: '100%',
              }}
            />
          </div>
        </div>
        <div>
          <div>
            <label htmlFor="url" style={{ fontWeight: 'bold' }}>
              URL
            </label>
          </div>
          <div>
            <input
              onChange={onUrlChange}
              value={url || ''}
              type="text"
              id="url"
              name="url"
              style={{
                padding: '12px 12px',
                margin: '8px 0',
                display: 'inline-block',
                border: '1px solid #ccc',
                borderRadius: '4px',
                boxSizing: 'border-box',
                width: '100%',
              }}
            />
          </div>
        </div>
        <button
          style={{
            padding: '12px 12px',
            margin: '8px 0',
            border: 'none',
            borderRadius: '8px',
            boxSizing: 'border-box',
            width: '100%',
            cursor: 'pointer',
          }}
          onClick={onPressAdd}
        >
          Add
        </button>
        <button
          style={{
            padding: '12px 12px',
            margin: '8px 0',
            border: 'none',
            borderRadius: '8px',
            boxSizing: 'border-box',
            width: '100%',
            cursor: 'pointer',
          }}
          onClick={onPressCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  )
}
