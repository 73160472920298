import { TILE_SIZE } from '../constants'
import { TSite } from '../types'
import useLongPress from '../useLongPress'
import { track } from '../utils'
import React from 'react'
import removeButton from '../images/removeButton.png'

const REMOVE_BUTTON_SIZE = 30
const REMOVE_BUTTON_MARGIN = -6

export function Site({
  name,
  url,
  image,
  style,
  onRemove,
  showRemoveButton,
}: TSite & { onRemove: () => void; showRemoveButton?: boolean }) {
  const { handlers } = useLongPress(onRemove, 500)

  return (
    <a
      href={
        showRemoveButton
          ? undefined
          : `https://www.youtube.com/redirect?q=${url}`
      }
      style={{
        textAlign: 'center',
        color: '#fbf7ff',
        marginBottom: 24,
        marginTop: 0,
      }}
      {...handlers}
      onClick={(e) => {
        if (showRemoveButton) return
        e.preventDefault()

        track('site_opened', { name, url })

        window.open(`https://www.youtube.com/redirect?q=${url}`, '_self')
      }}
      id="open-website"
    >
      <div style={style}>
        {showRemoveButton && (
          <div
            style={{
              position: 'absolute',
              marginLeft: REMOVE_BUTTON_MARGIN,
              marginTop: REMOVE_BUTTON_MARGIN,
              cursor: 'pointer',
            }}
            onClick={onRemove}
          >
            <img
              src={removeButton}
              width={REMOVE_BUTTON_SIZE}
              height={REMOVE_BUTTON_SIZE}
              style={{
                borderRadius: REMOVE_BUTTON_SIZE / 2,
                backgroundColor: 'white',
              }}
            />
          </div>
        )}
        <img
          src={image}
          alt={name}
          style={{
            borderRadius: 20,
            height: TILE_SIZE,
            objectFit: 'cover',
            width: TILE_SIZE,
          }}
        />
        <div style={{ marginTop: 4 }}>{name}</div>
      </div>
    </a>
  )
}
