import React from 'react'

export function Intro({ onPressGotIt }: { onPressGotIt: () => void }) {
  return (
    <div
      style={{
        borderColor: 'grey',
        borderWidth: 'thin',
        borderStyle: 'solid',
        paddingRight: 20,
        paddingLeft: 20,
        paddingBottom: 12,
        margin: 6,
        color: '#D0BCE2',
        marginBottom: 16,
      }}
    >
      <p>Click any item below to open in fullscreen.</p>
      <p>{`Press the '+' icon to save a new site.`}</p>
      <p>{`To remove an item, press the Edit button.`}</p>
      <p>
        {`Running into issues, have feature requests or have other feedback? I'd
        love to hear it at `}
        <a
          style={{ color: 'white' }}
          href="mailto:support@fullscreentesla.com?subject=Fullscreen Tesla Feedback"
        >
          support@fullscreentesla.com
        </a>
        !
      </p>
      <button style={{ padding: 8 }} onClick={onPressGotIt}>
        Got it
      </button>
    </div>
  )
}
