import { Helmet } from 'react-helmet'
import React from 'react'
import { DEFAULT_SITES } from '../constants'
import { sortSites } from '../utils'

export const Head = () => {
  const siteNames = sortSites(DEFAULT_SITES)
    .map((x) => x.name)
    .join(', ')
  const descriptionText = `Easily full-screen any website on your Tesla. Watch ${siteNames} and much more in full screen on your Tesla!`

  return (
    <Helmet>
      <meta name="description" content={descriptionText} />
    </Helmet>
  )
}
