import { TSite } from './types'
import * as amplitude from '@amplitude/analytics-browser'
import {
  LOCALSTORAGE_ADDED_SITES_KEY,
  DEFAULT_SITES,
  LOCALSTORAGE_REMOVED_SITES_KEY,
} from './constants'
import { uniqBy } from 'lodash'

export const getIsMobileDevice = () => {
  return 'share' in navigator
}

export const getIsTeslaBrowser = () => navigator.userAgent.includes('Tesla')

export const sortSites = (sites: TSite[]) => {
  return [...sites].sort((a, b) => {
    if (a.name < b.name) return -1
    else if (a.name > b.name) return 1
    return 0
  })
}

declare let window: Window &
  typeof globalThis & {
    gtag: (
      command: 'event',
      eventName: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      properties: { [key in string]: any }
    ) => void
  }

let initializedAmplitude = false

export const initializeAmplitude = () => {
  if (!initializedAmplitude) {
    amplitude.init('3b4557c81bc05195a91404b05ab97994')
    initializedAmplitude = true
  }
}

export const track = (
  eventName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: { [key: string]: any } = {}
) => {
  initializeAmplitude()
  amplitude.track(eventName, properties)

  try {
    window.gtag('event', eventName, properties)
  } catch {
    console.debug('failed event tracking')
  }
}

export function getStoredSites(): TSite[] {
  const currentSitesString = localStorage.getItem(LOCALSTORAGE_ADDED_SITES_KEY)
  return currentSitesString ? JSON.parse(currentSitesString) : []
}

export function getRemovedSites(): Pick<TSite, 'url'>[] {
  return JSON.parse(
    localStorage.getItem(LOCALSTORAGE_REMOVED_SITES_KEY) || '[]'
  )
}

export const getDefaultAndCustomSites = () =>
  sortSites(
    uniqBy([...DEFAULT_SITES, ...getStoredSites()], (x) => x.url).filter(
      (site) =>
        !getRemovedSites()
          .map((x) => x.url)
          .includes(site.url)
    )
  )

export function saveCustomSite({ name, url, image }: TSite) {
  const currentSitesString = localStorage.getItem(LOCALSTORAGE_ADDED_SITES_KEY)
  const currentItems = currentSitesString ? JSON.parse(currentSitesString) : []
  const updatedItems = [...currentItems, { name, url, image }]
  localStorage.setItem(
    LOCALSTORAGE_ADDED_SITES_KEY,
    JSON.stringify(updatedItems)
  )
}

export function removeSite({ name, url }: Omit<TSite, 'image'>) {
  const confirmation = confirm(
    `Do you really want to remove ${name}?\n\nOnce removed, you can use the (+) button below to add it again if you like.`
  )

  if (!confirmation) return false

  track('site_removed', { name, url })

  const currentRemovedSitesString = localStorage.getItem(
    LOCALSTORAGE_REMOVED_SITES_KEY
  )
  const currentRemovedItems = currentRemovedSitesString
    ? JSON.parse(currentRemovedSitesString)
    : []
  const updatedRemovedItems = [...currentRemovedItems, { url }]

  localStorage.setItem(
    LOCALSTORAGE_REMOVED_SITES_KEY,
    JSON.stringify(updatedRemovedItems)
  )
}
