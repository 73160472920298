import React, { useCallback, useState } from 'react'

import './App.css'
import {
  getDefaultAndCustomSites,
  initializeAmplitude,
  removeSite,
  saveCustomSite,
  track,
} from './utils'
import { AddItem } from './components/addItem'
import { Intro } from './components/help'
import { HelpButton } from './components/helpButton'
import { Site } from './components/site'
import { TILE_SIZE } from './constants'
import { TSite } from './types'
import { Head } from './components/head'
import { EditButton } from './components/editButton'

initializeAmplitude()

const SITE_MARGIN = 4
const PADDING = 20
const itemsPerRow = Math.floor(
  (window.innerWidth - 2 * PADDING) / (TILE_SIZE + 2 * SITE_MARGIN)
)

const numRows = Math.ceil((getDefaultAndCustomSites().length + 1) / itemsPerRow)

const spacesLeftOnLastRow =
  itemsPerRow * numRows - (getDefaultAndCustomSites().length + 1)

const SITE_BACKGROUND = '#232127'

function App() {
  const [sites, setSites] = useState<TSite[]>(getDefaultAndCustomSites())

  const updateSites = useCallback(() => {
    setSites(getDefaultAndCustomSites())
  }, [])

  const [isAdding, setIsAdding] = useState(false)

  const [name, setName] = useState<string | undefined>(undefined)
  const [url, setUrl] = useState<string | undefined>(undefined)

  const startAdding = useCallback(() => setIsAdding(true), [])

  const onPressCancel = useCallback(() => {
    setIsAdding(false)
  }, [])

  const onPressAdd = useCallback(
    (name?: string, url?: string) => {
      setIsAdding(false)

      if (name && url) {
        const imageUrl = `https://logo.clearbit.com/${url}?size=800`
        saveCustomSite({ name, url, image: imageUrl })
        track('custom_site_added', { name, url })
        setName(undefined)
        setUrl(undefined)
        updateSites()
      }
    },
    [updateSites]
  )

  const onRemove = useCallback(
    (site: TSite) => {
      removeSite({ name: site.name, url: site.url })
      updateSites()
    },
    [updateSites]
  )

  const [showHelp, setShowHelp] = useState(false)

  const [isEditting, setIsEditting] = useState(false)

  const HEADER_PADDING_VERTICAL = 10
  const HEADER_PADDING_HORIZONTAL = 10

  return (
    <div
      className="App"
      style={{
        padding: PADDING,
        backgroundColor: SITE_BACKGROUND,
      }}
    >
      <Head />
      {!showHelp && (
        <div
          style={{
            marginBottom: 16,
            backgroundColor: '#2f2c34',
            paddingTop: HEADER_PADDING_VERTICAL,
            paddingBottom: HEADER_PADDING_VERTICAL,
            paddingLeft: HEADER_PADDING_HORIZONTAL,
            paddingRight: HEADER_PADDING_HORIZONTAL,
            marginLeft: -PADDING,
            marginRight: -PADDING,
            marginTop: -PADDING,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <HelpButton onPress={() => setShowHelp(true)} />
            <EditButton isEditting={isEditting} setIsEditting={setIsEditting} />
          </div>
          <a
            href="https://titangymapp.com"
            style={{
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              width: 225,
            }}
            onClick={(e) => {
              e.preventDefault()
              track('titan_link_clicked')
              window.location.href = 'https://titangymapp.com'
            }}
          >
            <div>
              <div>Looking for a workout tracker?</div>
              <div>Try mine!</div>
            </div>

            <img src="titanQr.png" width={75} height={75} />
          </a>
        </div>
      )}
      {isAdding && (
        <AddItem
          onPressAdd={() => onPressAdd(name, url)}
          onNameChange={(e) => setName(e.target.value)}
          onUrlChange={(e) => setUrl(e.target.value)}
          url={url}
          name={name}
          onPressCancel={() => onPressCancel()}
        />
      )}
      {showHelp && <Intro onPressGotIt={() => setShowHelp(false)} />}
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          flexWrap: 'wrap',
          justifySelf: 'center',
          alignSelf: 'center',
          justifyContent: 'center',
        }}
      >
        {sites.map((siteInfo, i) => (
          <Site
            {...siteInfo}
            key={i}
            style={{ marginBottom: 50, margin: SITE_MARGIN }}
            onRemove={() => onRemove(siteInfo)}
            showRemoveButton={isEditting}
          />
        ))}

        <div
          style={{
            textAlign: 'center',
            marginBottom: 24 + 50,
          }}
        >
          <button
            style={{
              borderRadius: 20,
              borderWidth: 0,
              cursor: 'pointer',
              fontSize: 100,
              height: TILE_SIZE,
              margin: 4,
              width: TILE_SIZE,
              backgroundColor: SITE_BACKGROUND,
              color: 'white',
            }}
            onClick={startAdding}
          >
            +
          </button>
          <div style={{ color: '#D0BCE2' }}>Add custom site</div>
        </div>
        {new Array(spacesLeftOnLastRow).fill(undefined).map((x, i) => (
          <div
            key={i}
            style={{
              width: TILE_SIZE + 2 * SITE_MARGIN,
              height: 0,
            }}
          />
        ))}
      </div>
    </div>
  )
}

console.log('xxx', itemsPerRow, spacesLeftOnLastRow)

export default App
