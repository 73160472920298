import _9anime from './images/9anime.jpg'
import appleTv from './images/appleTv.png'
import coupang from './images/coupang.png'
import crave from './images/crave.webp'
import crunchyroll from './images/crunchyroll.png'
import disneyPlus from './images/disneyPlus.png'
import fubo from './images/fubo.webp'
import google from './images/google.png'
import hackernews from './images/hackernews.webp'
import hboMax from './images/hboMax.jpeg'
import hulu from './images/hulu.jpeg'
import netflix from './images/netflix.png'
import paramountPlus from './images/paramountPlus.png'
import peacock from './images/peacock.png'
import plex from './images/plex.webp'
import prime from './images/prime.png'
import reddit from './images/reddit.png'
import nebula from './images/nebula.png'
import sling from './images/sling.jpeg'
import tikTok from './images/tikTok.svg'
import xbox from './images/xbox.png'
import youtube from './images/youtube.webp'
import youtubeTV from './images/youtubeTV.png'
import twitch from './images/twitch.png'
import geforceNow from './images/geforceNow.jpg'
import curiosityStream from './images/curiosityStream2.svg'
import { TSite } from './types'

export const TILE_SIZE =
  window.innerWidth <= 500 ? window.innerWidth / 2.5 : 200

export const DEFAULT_SITES: TSite[] = [
  { name: 'Netflix', image: netflix, url: 'https://netflix.com' },
  { name: 'YouTube', image: youtube, url: 'https://youtube.com' },
  { name: 'Disney+', image: disneyPlus, url: 'https://disneyplus.com' },
  { name: 'Apple TV', image: appleTv, url: 'https://tv.apple.com/' },
  { name: 'Max', image: hboMax, url: 'https://www.max.com/' },
  { name: 'Prime Video', image: prime, url: 'https://amazon.com/primevideo' },
  { name: 'Hulu', image: hulu, url: 'https://hulu.com' },
  { name: 'TikTok', image: tikTok, url: 'https://tiktok.com' },
  {
    name: 'Curiosity Stream',
    image: curiosityStream,
    url: 'https://curiositystream.com/',
  },
  {
    name: 'Nebula',
    image: nebula,
    url: 'https://nebula.tv',
  },
  {
    name: 'Google',
    image: google,
    url: 'https://google.com',
  },
  {
    name: 'Xbox Cloud',
    image: xbox,
    url: 'https://www.xbox.com/play/',
  },
  {
    name: 'Plex',
    image: plex,
    url: 'https://app.plex.tv',
  },
  {
    name: 'Crave',
    image: crave,
    url: 'https://www.crave.ca/en',
  },
  {
    name: 'Sling',
    image: sling,
    url: 'https://www.sling.com/',
  },
  {
    name: 'Peacock',
    image: peacock,
    url: 'https://www.peacocktv.com/',
  },
  {
    name: 'FuboTV',
    image: fubo,
    url: 'https://www.fubo.tv/welcome',
  },
  {
    name: 'Crunchyroll',
    image: crunchyroll,
    url: 'https://www.crunchyroll.com/',
  },
  {
    name: '9Anime',
    image: _9anime,
    url: 'https://9animetv.to/',
  },
  {
    name: 'Paramount+',
    image: paramountPlus,
    url: 'https://www.paramountplus.com/ca/',
  },
  {
    name: 'CoupangPlay',
    image: coupang,
    url: 'https://coupangplay.com',
  },
  {
    name: 'YouTube TV',
    image: youtubeTV,
    url: 'https://tv.youtube.com/',
  },
  {
    name: 'Reddit',
    image: reddit,
    url: 'https://old.reddit.com/',
  },
  {
    name: 'HackerNews',
    image: hackernews,
    url: 'https://news.ycombinator.com/',
  },
  {
    name: 'Twitch',
    image: twitch,
    url: 'https://www.twitch.tv/',
  },
  {
    name: 'GeForce Now',
    image: geforceNow,
    url: 'https://play.geforcenow.com/mall/#/layout/games',
  },
]

export const LOCALSTORAGE_ADDED_SITES_KEY = 'sites'
export const LOCALSTORAGE_REMOVED_SITES_KEY = 'removed_sites'
