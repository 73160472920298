import React from 'react'

export const EditButton = ({
  isEditting,
  setIsEditting,
}: {
  isEditting: boolean
  setIsEditting: (x: boolean) => void
}) => (
  <button
    style={{ padding: 8, marginLeft: 4 }}
    onClick={() => {
      isEditting ? setIsEditting(false) : setIsEditting(true)
    }}
  >
    {isEditting ? 'Done' : 'Edit'}
  </button>
)
